@-webkit-keyframes spinAround {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spinAround {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.timeline {
    display: flex;
    flex-direction: column;
}

.timeline .timeline-header {
    width: 4em;
    min-width: 4em;
    max-width: 8em;
    word-wrap: normal;
    text-align: center;
    display: flex;
    justify-content: center;
}

.timeline .timeline-item {
    display: flex;
    position: relative;
 
    padding-bottom: 2em;
}

.timeline .timeline-item::before {
    content: "";
    background-color: #dbdbdb;
    display: block;
    width: 0.1em;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.timeline .timeline-item .timeline-marker {
    position: absolute;
    background: #dbdbdb;
    border: 0.1em solid #dbdbdb;
    border-radius: 50%;
    display: block;
    height: 0.8em;
    left: -0.35em;
    top: 1.2rem;
    width: 0.8em;
}

.timeline .timeline-item .timeline-marker.is-image {
    background: #dbdbdb;
    border: 0.1em solid #dbdbdb;
    border-radius: 50%;
    display: block;
    overflow: hidden;
}

.timeline .timeline-item .timeline-marker.is-image.is-16x16 {
    height: 16px;
    width: 16px;
    left: -8px;
}

.timeline .timeline-item .timeline-marker.is-image.is-24x24 {
    height: 24px;
    width: 24px;
    left: -12px;
}

.timeline .timeline-item .timeline-marker.is-image.is-32x32 {
    height: 32px;
    width: 32px;
    left: -16px;
}

.timeline .timeline-item .timeline-marker.is-image.is-48x48 {
    height: 48px;
    width: 48px;
    left: -24px;
}

.timeline .timeline-item .timeline-marker.is-image.is-64x64 {
    height: 64px;
    width: 64px;
    left: -32px;
}

.timeline .timeline-item .timeline-marker.is-image.is-96x96 {
    height: 96px;
    width: 96px;
    left: -48px;
}

.timeline .timeline-item .timeline-marker.is-image.is-128x128 {
    height: 128px;
    width: 128px;
    left: -64px;
}

.timeline .timeline-item .timeline-marker.is-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5em;
    width: 1.5em;
    left: -0.7em;
    line-height: 0.75rem;
    padding: 0.25rem;
    background: #dbdbdb;
    border: 0.1em solid #dbdbdb;
    border-radius: 50%;
}

.timeline .timeline-item .timeline-marker.is-icon > i {
    color: #fff;
    font-size: 0.75rem !important;
}

.timeline .timeline-item .timeline-marker.is-outlined .image {
    background: #fff;
}

.timeline .timeline-item .timeline-marker.is-outlined.is-icon {
    background: #fff;
}

.timeline .timeline-item .timeline-marker.is-outlined.is-icon > i {
    color: #dbdbdb;
}

.timeline .timeline-item .timeline-marker.is-white {
    background-color: #fff !important;
    border-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-white .image {
    border-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-white.is-icon {
    background-color: #fff !important;
    border-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-white.is-icon > i {
    color: #0a0a0a !important;
}

.timeline .timeline-item .timeline-marker.is-white.is-outlined {
    background-color: #fff !important;
    border-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-white.is-outlined .image {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-white.is-outlined.is-icon {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-white.is-outlined.is-icon > i {
    color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-black {
    background-color: #0a0a0a !important;
    border-color: #0a0a0a !important;
}

.timeline .timeline-item .timeline-marker.is-black .image {
    border-color: #0a0a0a !important;
}

.timeline .timeline-item .timeline-marker.is-black.is-icon {
    background-color: #0a0a0a !important;
    border-color: #0a0a0a !important;
}

.timeline .timeline-item .timeline-marker.is-black.is-icon > i {
    color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-black.is-outlined {
    background-color: #fff !important;
    border-color: #0a0a0a !important;
}

.timeline .timeline-item .timeline-marker.is-black.is-outlined .image {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-black.is-outlined.is-icon {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-black.is-outlined.is-icon > i {
    color: #0a0a0a !important;
}

.timeline .timeline-item .timeline-marker.is-light {
    background-color: #f5f5f5 !important;
    border-color: #f5f5f5 !important;
}

.timeline .timeline-item .timeline-marker.is-light .image {
    border-color: #f5f5f5 !important;
}

.timeline .timeline-item .timeline-marker.is-light.is-icon {
    background-color: #f5f5f5 !important;
    border-color: #f5f5f5 !important;
}

.timeline .timeline-item .timeline-marker.is-light.is-icon > i {
    color: #363636 !important;
}

.timeline .timeline-item .timeline-marker.is-light.is-outlined {
    background-color: #fff !important;
    border-color: #f5f5f5 !important;
}

.timeline .timeline-item .timeline-marker.is-light.is-outlined .image {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-light.is-outlined.is-icon {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-light.is-outlined.is-icon > i {
    color: #f5f5f5 !important;
}

.timeline .timeline-item .timeline-marker.is-dark {
    background-color: #363636 !important;
    border-color: #363636 !important;
}

.timeline .timeline-item .timeline-marker.is-dark .image {
    border-color: #363636 !important;
}

.timeline .timeline-item .timeline-marker.is-dark.is-icon {
    background-color: #363636 !important;
    border-color: #363636 !important;
}

.timeline .timeline-item .timeline-marker.is-dark.is-icon > i {
    color: #f5f5f5 !important;
}

.timeline .timeline-item .timeline-marker.is-dark.is-outlined {
    background-color: #fff !important;
    border-color: #363636 !important;
}

.timeline .timeline-item .timeline-marker.is-dark.is-outlined .image {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-dark.is-outlined.is-icon {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-dark.is-outlined.is-icon > i {
    color: #363636 !important;
}

.timeline .timeline-item .timeline-marker.is-primary {
    background-color: #00d1b2 !important;
    border-color: #00d1b2 !important;
}

.timeline .timeline-item .timeline-marker.is-primary .image {
    border-color: #00d1b2 !important;
}

.timeline .timeline-item .timeline-marker.is-primary.is-icon {
    background-color: #00d1b2 !important;
    border-color: #00d1b2 !important;
}

.timeline .timeline-item .timeline-marker.is-primary.is-icon > i {
    color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-primary.is-outlined {
    background-color: #fff !important;
    border-color: #00d1b2 !important;
}

.timeline .timeline-item .timeline-marker.is-primary.is-outlined .image {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-primary.is-outlined.is-icon {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-primary.is-outlined.is-icon > i {
    color: #00d1b2 !important;
}

.timeline .timeline-item .timeline-marker.is-link {
    background-color: #3273dc !important;
    border-color: #3273dc !important;
}

.timeline .timeline-item .timeline-marker.is-link .image {
    border-color: #3273dc !important;
}

.timeline .timeline-item .timeline-marker.is-link.is-icon {
    background-color: #3273dc !important;
    border-color: #3273dc !important;
}

.timeline .timeline-item .timeline-marker.is-link.is-icon > i {
    color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-link.is-outlined {
    background-color: #fff !important;
    border-color: #3273dc !important;
}

.timeline .timeline-item .timeline-marker.is-link.is-outlined .image {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-link.is-outlined.is-icon {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-link.is-outlined.is-icon > i {
    color: #3273dc !important;
}

.timeline .timeline-item .timeline-marker.is-info {
    background-color: #209cee !important;
    border-color: #209cee !important;
}

.timeline .timeline-item .timeline-marker.is-info .image {
    border-color: #209cee !important;
}

.timeline .timeline-item .timeline-marker.is-info.is-icon {
    background-color: #209cee !important;
    border-color: #209cee !important;
}

.timeline .timeline-item .timeline-marker.is-info.is-icon > i {
    color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-info.is-outlined {
    background-color: #fff !important;
    border-color: #209cee !important;
}

.timeline .timeline-item .timeline-marker.is-info.is-outlined .image {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-info.is-outlined.is-icon {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-info.is-outlined.is-icon > i {
    color: #209cee !important;
}

.timeline .timeline-item .timeline-marker.is-success {
    background-color: #23d160 !important;
    border-color: #23d160 !important;
}

.timeline .timeline-item .timeline-marker.is-success .image {
    border-color: #23d160 !important;
}

.timeline .timeline-item .timeline-marker.is-success.is-icon {
    background-color: #23d160 !important;
    border-color: #23d160 !important;
}

.timeline .timeline-item .timeline-marker.is-success.is-icon > i {
    color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-success.is-outlined {
    background-color: #fff !important;
    border-color: #23d160 !important;
}

.timeline .timeline-item .timeline-marker.is-success.is-outlined .image {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-success.is-outlined.is-icon {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-success.is-outlined.is-icon > i {
    color: #23d160 !important;
}

.timeline .timeline-item .timeline-marker.is-warning {
    background-color: #ffdd57 !important;
    border-color: #ffdd57 !important;
}

.timeline .timeline-item .timeline-marker.is-warning .image {
    border-color: #ffdd57 !important;
}

.timeline .timeline-item .timeline-marker.is-warning.is-icon {
    background-color: #ffdd57 !important;
    border-color: #ffdd57 !important;
}

.timeline .timeline-item .timeline-marker.is-warning.is-icon > i {
    color: rgba(0, 0, 0, 0.7) !important;
}

.timeline .timeline-item .timeline-marker.is-warning.is-outlined {
    background-color: #fff !important;
    border-color: #ffdd57 !important;
}

.timeline .timeline-item .timeline-marker.is-warning.is-outlined .image {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-warning.is-outlined.is-icon {
    background-color: #fff !important;
}

.timeline .timeline-marker.is-warning.is-outlined.is-icon > i {
    color: #ffdd57 !important;
}

.timeline .timeline-item .timeline-marker.is-danger {
    background-color: #ff3860 !important;
    border-color: #ff3860 !important;
}

.timeline .timeline-item .timeline-marker.is-danger .image {
    border-color: #ff3860 !important;
}

.timeline .timeline-item .timeline-marker.is-danger.is-icon {
    background-color: #ff3860 !important;
    border-color: #ff3860 !important;
}

.timeline .timeline-item .timeline-marker.is-danger.is-icon > i {
    color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-danger.is-outlined {
    background-color: #fff !important;
    border-color: #ff3860 !important;
}

.timeline .timeline-item .timeline-marker.is-danger.is-outlined .image {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-danger.is-outlined.is-icon {
    background-color: #fff !important;
}

.timeline .timeline-item .timeline-marker.is-danger.is-outlined.is-icon > i {
    color: #ff3860 !important;
}

.timeline .timeline-item .timeline-content {
    padding: 0.5em 0.5em 0.5em 1em;
   
}



.timeline .timeline-item.is-white::before {
    background-color: #fff;
}

.timeline .timeline-item.is-black::before {
    background-color: #0a0a0a;
}

.timeline .timeline-item.is-light::before {
    background-color: #f5f5f5;
}

.timeline .timeline-item.is-dark::before {
    background-color: #363636;
}

.timeline .timeline-item.is-primary::before {
    background-color: #00d1b2;
}

.timeline .timeline-item.is-link::before {
    background-color: #3273dc;
}

.timeline .timeline-item.is-info::before {
    background-color: #209cee;
}

.timeline .timeline-item.is-success::before {
    background-color: #23d160;
}

.timeline .timeline-item.is-warning::before {
    background-color: #ffdd57;
}

.timeline .timeline-item.is-danger::before {
    background-color: #ff3860;
}

.timeline.is-centered .timeline-header {
    display: flex;
    width: 100%;
    align-self: center;
}

.timeline.is-centered .timeline-item {
    width: 50%;
    align-self: flex-end;
}

.timeline.is-centered .timeline-item:nth-of-type(2n) {
    align-self: flex-start;
   
 
}

.timeline.is-centered .timeline-item:nth-of-type(2n)::before {
    right: -0.1em;
    left: auto;
}

.timeline.is-centered .timeline-item:nth-of-type(2n) .timeline-marker {
    left: auto;
    right: -0.45em;
}

.timeline.is-centered .timeline-item:nth-of-type(2n) .timeline-marker.is-image.is-16x16 {
    left: auto;
    right: -8px;
}

.timeline.is-centered .timeline-item:nth-of-type(2n) .timeline-marker.is-image.is-24x24 {
    left: auto;
    right: -12px;
}

.timeline.is-centered .timeline-item:nth-of-type(2n) .timeline-marker.is-image.is-32x32 {
    left: auto;
    right: -16px;
}

.timeline.is-centered .timeline-item:nth-of-type(2n) .timeline-marker.is-image.is-48x48 {
    left: auto;
    right: -24px;
}

.timeline.is-centered .timeline-item:nth-of-type(2n) .timeline-marker.is-image.is-64x64 {
    left: auto;
    right: -32px;
}

.timeline.is-centered .timeline-item:nth-of-type(2n) .timeline-marker.is-image.is-96x96 {
    left: auto;
    right: -48px;
}

.timeline.is-centered .timeline-item:nth-of-type(2n) .timeline-marker.is-image.is-128x128 {
    left: auto;
    right: -64px;
}

.timeline.is-centered .timeline-item:nth-of-type(2n) .timeline-marker.is-icon {
    left: auto;
    right: -0.8em;
}



.timeline.is-centered .timeline-item.is-white::before {
    background-color: #fff;
}

.timeline.is-centered .timeline-item.is-black::before {
    background-color: #0a0a0a;
}

.timeline.is-centered .timeline-item.is-light::before {
    background-color: #f5f5f5;
}

.timeline.is-centered .timeline-item.is-dark::before {
    background-color: #363636;
}

.timeline.is-centered .timeline-item.is-primary::before {
    background-color: #00d1b2;
}

.timeline.is-centered .timeline-item.is-link::before {
    background-color: #3273dc;
}

.timeline.is-centered .timeline-item.is-info::before {
    background-color: #209cee;
}

.timeline.is-centered .timeline-item.is-success::before {
    background-color: #23d160;
}

.timeline.is-centered .timeline-item.is-warning::before {
    background-color: #ffdd57;
}

.timeline.is-centered .timeline-item.is-danger::before {
    background-color: #ff3860;
}


.timeline-item .timeline-marker.is-image img {
    display: block;
    width: 100%;
    height: 100%;
  }
  