.chatbot-container {
    position: fixed;
    bottom: 5vh;
    right: 5vh;
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    border-radius: 50%; /* Make it circular */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    z-index: 1000; /* Ensure it's above other content */
    /* Initial Size - matches the icon size */
    width: 60px;
    height: 60px;
  }
  
  .chatbot-header {
    padding: 10px;
    background-color:  #be50f4;
    color: white;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chatbot-header h2 {
    font-size: 1rem;
    margin: 0;
  }
  
  .toggle-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .messages-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 44vh;
    overflow-y: scroll;
  }
  
  .message {
    max-width: 80%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 18px;
    line-height: 1.4;
  }
  
  .message.user {
    align-self: flex-end;
    background-color:  #be50f4;
    color: white;
  }
  
  .message.bot {
    align-self: flex-start;
    background-color:  #be50f4;
    color: white;
  }
  
  .chatbot-form {
    display: flex;
    border-top: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 0; /* Remove bottom margin */
  }
  
  .chatbot-form input {
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
  }
  
  .chatbot-form button {
    padding: 10px 15px;
    background-color:#be50f4;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .chatbot-form button:hover {
    background-color: rgb(81, 60, 81);
  }
  
  .chatbot-form button:disabled {
    background-color: #6c757d;
  }

  
  .chatbot-closed-icon {
    width: 60px;
    height: 60px;
    background-color: #be50f4; /* Your Primary Color */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Icon Color */
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    
  }
  
  /* Container style adjustments */
  .chatbot-container {
    width: 60px; /* Match icon size initially */
    height: 60px; /* Match icon size initially */
    transition: all 0.3s ease-in-out;
    transform-origin: bottom right; /* Ensure expansion is anchored to the icon's position */
    /* ... other styles ... */
  }
  
  /* Expanded state */
  .chatbot-container.open {
    width: 40vh; /* Original width */
    height: 60vh; /* Desired height */
    border-radius: 10px; /* Restore border-radius for chat interface */
    /* Adjust position if necessary */
  }
  
  /* Hide header title when closed */
  .chatbot-container.closed .chatbot-header h2 {
    display: none;
  }

  .chatbot-content {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .chatbot-container.open .chatbot-content {
    opacity: 1;
  }
  
  
  
  /* Add media queries as needed for responsiveness */
  