.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.futuristic-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  color: #30D5C8; /* Neon blue/green color */
  text-transform: uppercase;
  text-shadow: 3px 3px 0px #555, 6px 6px 0px rgba(0, 0, 0, 0.2); /* Shadow effect for depth */
  transform: perspective(600px) rotateY(15deg); /* 3D effect by transforming the text */
}